import { createRouter, createWebHistory } from 'vue-router'
// import store from '../store'
import MainView from '../views/MainView.vue'
import ErrorView from '../views/ErrorView.vue'
import MainPage from '../pages/MainPage.vue'
import ChallengesPage from '@/pages/ChallengesPage.vue'

const routes = [
  {
    path: '/',
    component: MainView,
    meta: { alreadyAuth: true },
    children: [
      {
        path: '/home',
        alias: '/',
        component: MainPage
      },
      {
        path: '/challenges',
        alias: '/challenges',
        component: ChallengesPage
      },
    ]
  },

  {
    path: '/:pathMatch(.*)*',
    name: 'ErrorView',
    component: ErrorView,
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  
  // eslint-disable-next-line
  scrollBehavior(to, from, savedPosition) {
    return { top: 0}
  },
})

// router.beforeEach((to, from, next) => {
//   if (to.path === '/profile' && store.state.user === null) {
//     next('/error-page')
//   } else {
//     next()
//   }
// })

export default router
