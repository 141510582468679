<template>
    <section class="h-screen">
            <div class="pt-10 flex flex-col justify-center items-center font-lato">
                <img loading="lazy" src="../assets/logo.png" class="w-[300px]" alt="ballyboy">
                <div class="text-white text-[50px] mx-auto text-center">Page Not Found</div>
                <div class="text-white text-lg mx-auto text-center px-6">
                    The page you are looking for might have been removed, had its name changed, or is temporarily unavailable.
                </div>
                <div class="mt-10">
                    <router-link to="/" class="px-4 py-3 text-black font-extrabold uppercase bg-[#FFCE00]">
                        Back to Home
                    </router-link>
                </div>
            </div>
    </section>
</template>

<script>
    export default {
        methods: {
            isMobile() {
                return window.innerWidth < 1024;
            },
        }
    }
</script>

<style scoped>

</style>