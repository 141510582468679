<template>
    <section class="h-auto">
        <RoobetChallengesSection/>
    </section>
</template>

<script>
import RoobetChallengesSection from '@/sections/RoobetChallengesSection.vue';

    export default {
        components: {
            RoobetChallengesSection
        }
    }
</script>

<style scoped>

</style>