<template>
    <section class="h-screen z-50">
        <div class="h-full">
            <div class="flex flex-col justify-center items-center">
                <img loading="lazy" src="../assets/logo.png" class="w-[300px] mt-24" alt="oeg">
                <div class="text-white text-[30px] mx-auto" style="font-family: Oxanium, sans-serif;">Loading....</div>
                <div class="mt-14 w-24 h-24 rounded-full animate-spin
                    border-y-8 border-solid border-white border-t-transparent">
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    export default {
        
    }
</script>

<style scoped>
</style>