<template>
    <div>
      <!-- Navbar -->
      <nav :class="{'bg-[#090B1D]/5': !isScrolled && !isMobile(), 'bg-[#090B1D]/100': isScrolled && !isMobile(), 'bg-[#090B1D]': isMobile() }" class=" fixed w-full shadow-md z-50 px-6 md:px-10 2xl:px-40">
        <div class="container mx-auto flex justify-between items-center">
          <!-- Logo -->
          <div class="flex items-center pr-4 py-2">
            <img src="../assets/austinski-nav-logo.png" class="w-48 md:w-[200px]" />
          </div>
  
          <!-- Menu Icon (Mobile) -->
          <div class="lg:hidden">
            <button @click.stop="toggleMobileMenu" class="text-white focus:outline-none mt-1">
              <svg v-motion-pop-visible v-show="mobileMenuOpen == false" xmlns="http://www.w3.org/2000/svg" class="h-7 w-7" fill="none" viewBox="0 0 24 24" stroke="#FFCE00">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16m-7 6h7" />
              </svg>
              <svg v-motion-pop-visible v-show="mobileMenuOpen == true" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#FFCE00" class="h-7 w-7">
                <path fill-rule="evenodd" d="M5.47 5.47a.75.75 0 0 1 1.06 0L12 10.94l5.47-5.47a.75.75 0 1 1 1.06 1.06L13.06 12l5.47 5.47a.75.75 0 1 1-1.06 1.06L12 13.06l-5.47 5.47a.75.75 0 0 1-1.06-1.06L10.94 12 5.47 6.53a.75.75 0 0 1 0-1.06Z" clip-rule="evenodd" />
              </svg>
            </button>
          </div>
  
          <!-- Links (Desktop) -->
          <div class="hidden lg:flex text-white text-sm uppercase font-bold tracking-widest font-lato lg:gap-8">
            <a href="/#main" class="py-1 px-6 hover:outline hover:outline-[1px] hover:outline-[#FFCE00] hover:bg-gradient-to-r hover:from-[#FFCE00]/20 rounded-xl">
              Home
            </a>
            <a href="/#leaderboards" class="py-1 px-6 hover:outline hover:outline-[1px] hover:outline-[#FFCE00] hover:bg-gradient-to-r hover:from-[#FFCE00]/20 rounded-xl">
              Leaderboards
            </a>
            <a href="/#socials" class="py-1 px-6 hover:outline hover:outline-[1px] hover:outline-[#FFCE00] hover:bg-gradient-to-r hover:from-[#FFCE00]/20 rounded-xl">
              Socials
            </a>
            <router-link to="/challenges" class="py-1 px-6 hover:outline hover:outline-[1px] hover:outline-[#FFCE00] hover:bg-gradient-to-r hover:from-[#FFCE00]/20 rounded-xl">
              Challenges
            </router-link>
          </div>
        </div>
      </nav>
      <!-- Sidebar Menu (Mobile) -->
      <transition name="slide">
        <div v-if="mobileMenuOpen" class="sidebar fixed bg-[#090B1D] w-60 md:w-80 h-screen z-40 mt-[58px]" @click.stop>
          <!-- Links -->
          <div class="flex flex-col uppercase font-lato">
            <a @click="mobileMenuOpen = false" href="/#main" class="text-white hover:text-[#FFCE00] flex items-center py-5 hover:bg-[#6D61A1]">
                <div class="flex flex-row justify-center items-center ml-4 gap-2">
                    <p class="tracking-widest font-bold text-xs">Home</p>
                </div>
            </a>
            <a @click="mobileMenuOpen = false" href="/#leaderboards" class="text-white hover:text-[#FFCE00] flex items-center py-5 hover:bg-[#6D61A1]">
                <div class="flex flex-row justify-center items-center ml-4 gap-2">
                    <p class="tracking-widest font-bold text-xs">Leaderboards</p>
                </div>
            </a>
            <a @click="mobileMenuOpen = false" href="/#socials" class="text-white hover:text-[#FFCE00] flex items-center py-5 hover:bg-[#6D61A1]">
                <div class="flex flex-row justify-center items-center ml-4 gap-2">
                    <p class="tracking-widest font-bold text-xs">Socials</p>
                </div>
            </a>
            <router-link @click="mobileMenuOpen = false" to="/challenges" class="text-white hover:text-[#FFCE00] flex items-center py-5 hover:bg-[#6D61A1]">
                <div class="flex flex-row justify-center items-center ml-4 gap-2">
                    <p class="tracking-widest font-bold text-xs">Challenges</p>
                </div>
            </router-link>
          </div>
        </div>
      </transition>
    </div>
    <!-- <div v-show="isLogout == true" class="relative z-50" aria-labelledby="modal-title" role="dialog" aria-modal="true"> 
        <div class="fixed inset-0 bg-black bg-opacity-70 transition-opacity" aria-hidden="true"></div>
        <div class="fixed inset-0 z-10 w-screen overflow-y-auto" style="font-family: Oxanium, sans-serif;">
            <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <div v-motion-pop-visible class="relative overflow-hidden text-center shadow-xl sm:my-8 sm:w-full sm:max-w-lg">
                <div class="cards-frame px-4 pb-4 pt-5 sm:pt-10 sm:px-14 sm:pb-10">
                  <div class="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-center">
                    <h3 class="text-xl font-semibold leading-6 text-white uppercase" id="modal-title">Logout Ballyboy account</h3>
                    <div class="mt-2">
                        <p class="text-white">Are you sure you want to logout your account?</p>
                    </div>
                  </div>
                  <div class="hidden sm:flex mt-8 flex-row justify-center gap-10 mb-2" style="font-family: Oxanium, sans-serif;">
                    <button @click="isLogout = false" type="button" class="mt-3 inline-flex w-full justify-center rounded-md px-14 py-4 text-sm font-semibold hover:text-white hover:bg-[#1689DB] shadow-lg sm:mt-0 sm:w-auto text-white bg-transparent border border-white hover:border-[#1294F0] font-base relative uppercase clip-path-button-left main-btn">
                      Cancel
                      <span class="absolute inset-x-0 bottom-0 h-1 bg-white mx-auto w-16"></span>
                      <span class="absolute inset-y-0 left-0 -mb-[20px] bg-transparent border-r-2 border-white transform skew-x-[35deg] z-0 main-span"></span>
                    </button>
                    <button @click="handleLogout()" type="button" class="mt-3 inline-flex w-full justify-center rounded-md px-14 py-4 text-sm font-semibold hover:text-white hover:bg-[#1689DB] shadow-lg sm:mt-0 sm:w-auto text-white bg-transparent border border-white hover:border-[#1294F0] font-base relative uppercase clip-path-button-right main-btn">
                      Confirm
                      <span class="absolute inset-x-0 bottom-0 h-1 bg-white mx-auto w-16"></span>
                      <span class="absolute inset-y-0 right-0 -mb-[20px] bg-transparent border-r-2 border-white transform skew-x-[143deg] z-0 leaderboard-span main-span"></span>
                    </button>
                  </div>
                  <div class="flex sm:hidden mt-4 flex-col justify-center" style="font-family: Oxanium, sans-serif;">
                    <button @click="handleLogout()" type="button" class="mt-3 inline-flex w-full justify-center rounded-md px-10 py-4 text-sm font-semibold hover:text-white hover:bg-[#1689DB] shadow-lg sm:mt-0 sm:w-auto text-white bg-transparent border border-white hover:border-[#1294F0] font-base relative uppercase main-btn">
                      Confirm
                      <span class="absolute inset-x-0 bottom-0 h-1 bg-white mx-auto w-24"></span>
                    </button>
                    <button @click="isLogout = false" type="button" class="mt-3 inline-flex w-full justify-center rounded-md px-14 py-4 text-sm font-semibold hover:text-white hover:bg-[#1689DB] shadow-lg sm:mt-0 sm:w-auto text-white bg-transparent border border-white hover:border-[#1294F0] font-base relative uppercase main-btn">
                      Cancel
                      <span class="absolute inset-x-0 bottom-0 h-1 bg-white mx-auto w-24"></span>
                    </button>
                  </div>
                </div>
            </div>
            </div>
        </div>
      </div> -->
</template>
  
<script>
  export default {
    emits: ['logout'],
    components: {
    },
    data() {
      return {
        mobileMenuOpen: false,
        isScrolled: false,

        isLogout: false,
      };
    },
    methods: {
      toggleMobileMenu() {
        this.mobileMenuOpen = !this.mobileMenuOpen;
      },
      handleClickOutside(event) {
        if (this.mobileMenuOpen && !event.target.closest('.sidebar')) {
          this.mobileMenuOpen = false;
        }
      },
      isMobile() {
        return window.innerWidth < 1024;
      },
      handleScroll() {
        this.isScrolled = window.scrollY > 0;

        // if (this.showMenu && window.innerWidth < 768) {
        //     this.isScrolled = true;
        // }
      },
      handleLogin() {
          const loginUrl = `${process.env.VUE_APP_BASE_API}/api/auth/discord`
          window.location.href = loginUrl
      },
      handleLogout(){
        this.$emit('logout')
      },
    },
    mounted() {
      window.addEventListener('click', this.handleClickOutside);
      window.addEventListener('scroll', this.handleScroll);
    },
    beforeUnmount() {
      window.removeEventListener('click', this.handleClickOutside);
      window.removeEventListener('scroll', this.handleScroll);
    },
  };
</script>
  
<style scoped>
.slide-enter-active, .slide-leave-active {
  transition: transform 0.3s ease;
}
.slide-enter-from {
  transform: translateX(-100%);
}
.slide-leave-to {
  transform: translateX(-100%);
}
.main-btn:hover .main-span {
  border-color: #1294F0;
}
</style>
  