<template>
    <section class="h-screen">
        <div class="flex flex-col justify-center items-center pt-40" style="font-family: Oxanium, sans-serif;">
            <p v-motion-pop-visible class="text-white font-extrabold text-5xl uppercase">Challenges</p>
            <p v-motion-pop-visible class="text-[#FFCE00] font-bold text-2xl uppercase">Coming Soon</p>
        </div>
        <div class="mt-10">
            <img src="../assets/socials-bg-path.png">
        </div>
    </section>
</template>

<script>
    export default {
        
    }
</script>

<style scoped>

</style>