<template>
    <!-- Leaderboard Section -->
    <section :class="loading == true ? 'h-screen': 'h-auto'" class="container mx-auto" id="leaderboard">
      <div class="flex justify-center">
        <div class="flex flex-col justify-center items-center p-8 rounded-3xl">
          <div v-motion-pop-visible class="mt-6">
              <div class="flex flex-row justify-evenly items-center">
                <div class="flex flex-col items-center gap-4">
                    <div class="flex flex-row gap-4" style="font-family: Oxanium, sans-serif;">
                        <div class="bg-gradient-to-b from-[#FFCE00] border border-[#FFCE00] rounded-lg flex flex-col justify-center items-center w-[65px] md:w-[80px] md:h-[80px]">
                            <span v-if="activeTab == 'current'" class="font-bold text-4xl text-white">{{ days }}</span>
                            <span v-else class="font-bold text-4xl text-white">0</span>
                            <div v-if="loading == true" class="mb-2" role="status">
                              <svg aria-hidden="true" class="inline w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-[#FFCE00]" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                                <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
                              </svg>
                            </div>
                            <span class="font-bold text-xs md:text-sm text-white uppercase">Days</span>
                        </div>
                        <div class="bg-gradient-to-b from-[#FFCE00] border border-[#FFCE00] rounded-lg flex flex-col justify-center items-center w-[65px] md:w-[80px] md:h-[80px]">
                            <span v-if="activeTab == 'current'"  class="font-bold text-4xl text-white">{{ hours }}</span>
                            <span v-else class="font-bold text-4xl text-white">0</span>
                            <div v-if="loading == true" class="mb-2" role="status">
                              <svg aria-hidden="true" class="inline w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-[#FFCE00]" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                                <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
                              </svg>
                            </div>
                            <span class="font-bold text-xs md:text-sm text-white uppercase">Hours</span>
                        </div>
                        <div class="bg-gradient-to-b from-[#FFCE00] border border-[#FFCE00] rounded-lg flex flex-col justify-center items-center w-[65px] md:w-[80px] md:h-[80px]">
                            <span v-if="activeTab == 'current'"  class="font-bold text-4xl text-white">{{ minutes }}</span>
                            <span v-else class="font-bold text-4xl text-white">0</span>
                            <div v-if="loading == true" class="mb-2" role="status">
                              <svg aria-hidden="true" class="inline w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-[#FFCE00]" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                                <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
                              </svg>
                            </div>
                            <span class="font-bold text-xs md:text-sm text-white uppercase">Minutes</span>
                        </div>
                        <div class="bg-gradient-to-b from-[#FFCE00] border border-[#FFCE00] rounded-lg flex flex-col justify-center items-center w-[65px] md:w-[80px] md:h-[80px]">
                            <span v-if="activeTab == 'current'"  class="font-bold text-4xl text-white">{{ seconds }}</span>
                            <span v-else class="font-bold text-4xl text-white">0</span>
                            <div v-if="loading == true" class="mb-2" role="status">
                              <svg aria-hidden="true" class="inline w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-[#FFCE00]" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                                <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
                              </svg>
                            </div>
                            <span class="font-bold text-xs md:text-sm text-white uppercase">Seconds</span>
                        </div>
                    </div>
                    <div class="flex flex-col md:flex-row gap-4 mt-4 px-8 font-lato">
                        <button v-motion-slide-visible-right :disabled="loading == true" @click="activeTab = 'current'; changeLeaderboard();" :class="{'bg-[#FFCE00] border-[#FFCE00] text-black': activeTab === 'current'}" class="text-white font-extrabold bg-transparent hover:bg-[#FFCE00] border border-white hover:border-[#FFCE00] hover:text-black font-base py-4 px-8 md:px-10 relative uppercase rounded-xl">
                            Current Leaderboard
                        </button>
                        <button v-motion-slide-visible-left :disabled="loading == true" @click="activeTab = 'previous'; changeLeaderboard();" :class="{'bg-[#FFCE00] border-[#FFCE00] text-black': activeTab === 'previous'}" class="text-white font-extrabold bg-transparent hover:bg-[#FFCE00] border border-white hover:border-[#FFCE00] hover:text-black font-base py-4 px-8 md:px-9 relative uppercase rounded-xl">
                            Previous Leaderboard
                        </button>
                    </div>
                </div>
              </div>
          </div>
          <div v-if="loading == true" class="text-center text-white text-2xl md:text-4xl mt-14 font-black font-lato">Loading Leaderboard...</div>
          <div v-if="loading == false"  class="hidden md:block">
            <div class="flex flex-row justify-center items-center gap-6 lg:gap-44 mt-52 font-lato">
              <div v-motion-pop-visible class="flex flex-col items-center mt-20 w-60">
                <div class="bg-gradient-to-t from-[#FFCE00]/30 border-2 border-[#D19700] w-full lg:w-[300px] h-[300px] rounded-xl">
                    <div class="flex flex-col justify-center items-center">
                        <div class="flex flex-col justify-between items-center -mt-[110px]">
                            <div class="bg-[#090B1D] file:w-[140px] h-[140px] rounded-full">
                                <div class="bg-gradient-to-t from-[#FFCE00]/20 border-2 border-[#D19700] w-[140px] h-[140px] rounded-full">
                                    <div class="text-[#D19700] text-5xl font-black mt-11 ml-7">
                                        2nd
                                    </div>
                                </div>
                            </div>
                            <div>
                            <span class="text-white font-bold text-xl">{{ top3.length > 0 && top3[1] != undefined ? top3[1].username : '---' }}</span>
                            </div>
                        </div>
                        <div class=" flex flex-col text-center w-full mt-6 gap-1">
                            <span class="text-white font-extrabold text-[42px] uppercase">$ {{ top3.length > 0 && top3[1] != undefined ? top3[1].weightedWagered.toFixed(2) : '---' }}</span>
                            <span class="text-white font-bold text-sm uppercase">Wagered</span>
                        </div>
                        <div class="flex flex-col text-center mt-8 py-4 border-2 border-[#D19700] px-8 rounded-md">
                            <span class="text-[#FFCE00] font-extrabold text-4xl uppercase">$ {{ activeTab == 'current' ? getRewardValue(1) : getPrevRewardValue(1)}}</span>
                        </div>
                    </div>
                </div>
              </div>
              <div v-motion-pop-visible class="flex flex-col items-center -mt-40 w-60">
                <div class="bg-gradient-to-t from-[#FFCE00]/30 border-2 border-[#D19700] w-full lg:w-[300px] h-[300px] rounded-xl">
                    <div class="flex flex-col justify-center items-center">
                        <div class="flex flex-col justify-between items-center -mt-[110px]">
                            <div class="bg-[#090B1D] file:w-[140px] h-[140px] rounded-full">
                                <div class="bg-gradient-to-t from-[#FFCE00]/20 border-2 border-[#D19700] w-[140px] h-[140px] rounded-full">
                                    <div class="text-[#D19700] text-5xl font-black mt-11 ml-8">
                                        1st
                                    </div>
                                </div>
                            </div>
                            <div>
                            <span class="text-white font-bold text-xl">{{ top3.length > 0 && top3[0] != undefined ? top3[0].username : '---' }}</span>
                            </div>
                        </div>
                        <div class=" flex flex-col text-center w-full mt-6 gap-1">
                            <span class="text-white font-extrabold text-[42px] uppercase">$ {{ top3.length > 0 && top3[0] != undefined ? top3[0].weightedWagered.toFixed(2) : '---' }}</span>
                            <span class="text-white font-bold text-sm uppercase">Wagered</span>
                        </div>
                        <div class="flex flex-col text-center mt-8 py-4 border-2 border-[#D19700] px-8 rounded-md">
                            <span class="text-[#FFCE00] font-extrabold text-4xl uppercase">$ {{ activeTab == 'current' ? getRewardValue(0) : getPrevRewardValue(0)}}</span>
                        </div>
                    </div>
                </div>
              </div>
              <div v-motion-pop-visible class="flex flex-col items-center mt-20 w-60">
                <div class="bg-gradient-to-t from-[#FFCE00]/30 border-2 border-[#D19700] w-full lg:w-[300px] h-[300px] rounded-xl">
                    <div class="flex flex-col justify-center items-center">
                        <div class="flex flex-col justify-between items-center -mt-[110px]">
                            <div class="bg-[#090B1D] file:w-[140px] h-[140px] rounded-full">
                                <div class="bg-gradient-to-t from-[#FFCE00]/20 border-2 border-[#D19700] w-[140px] h-[140px] rounded-full">
                                    <div class="text-[#D19700] text-5xl font-black mt-11 ml-7">
                                        3rd
                                    </div>
                                </div>
                            </div>
                            <div>
                            <span class="text-white font-bold text-xl">{{ top3.length > 0 && top3[2] != undefined ? top3[2].username : '---' }}</span>
                            </div>
                        </div>
                        <div class=" flex flex-col text-center w-full mt-6 gap-1">
                            <span class="text-white font-extrabold text-[42px] uppercase">$ {{ top3.length > 0 && top3[2] != undefined ? top3[2].weightedWagered.toFixed(2) : '---' }}</span>
                            <span class="text-white font-bold text-sm uppercase">Wagered</span>
                        </div>
                        <div class="flex flex-col text-center mt-8 py-4 border-2 border-[#D19700] px-8 rounded-md">
                            <span class="text-[#FFCE00] font-extrabold text-4xl uppercase">$ {{ activeTab == 'current' ? getRewardValue(2) : getPrevRewardValue(2)}}</span>
                        </div>
                    </div>
                </div>
              </div>
            </div>
            <div class="flex justify-center mt-8 mb:mb-24 font-lato">
              <table class="w-full border-separate border-spacing-y-4">
                <thead v-motion-slide-visible-bottom>
                  <tr>
                    <th
                      class="lg:pr-28 py-8 text-white text-[18px] md:text-xl font-bold text-left">
                      Ranks
                    </th>
                    <th
                      class="lg:px-28 py-8 text-white text-[18px] md:text-xl font-bold text-center">
                      User
                    </th>
                    <th
                      class="lg:px-28 py-8 text-white text-[18px] md:text-xl font-bold text-center">
                      Amount
                    </th>
                    <th
                      class="lg:pl-28 py-8 text-white text-[18px] md:text-xl font-bold text-right">
                      Prize
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-if="top10.length == 0">
                    <td colspan="4" class="text-center text-white font-bold text-lg py-4 font-lato">No data to show</td>
                  </tr>
                    <tr v-motion-slide-visible-bottom v-for="(item, index) in top10" :key="index" class="outline outline-[1px] outline-[#FFCE00] bg-gradient-to-r from-[#FFCE00]/0 via-[#FFCE00]/20 to-[#FFCE00]/0 hover:from-[#FFCE00]/20 hover:via-[#FFCE00]/0 hover:to-[#FFCE00]/20 rounded-lg">
                      <td
                        class="md:py-4 lg:py-3 pl-3 whitespace-nowrap text-[18px] text-white text-left rounded-l-lg">
                        {{ getOrdinalSuffix(index + 4) }}
                      </td>
                      <td
                        class="lg:py-3 whitespace-nowrap text-[18px] text-white text-center">
                          {{ item.username }}
                      </td>
                      <td
                        class="lg:py-3 whitespace-nowrap text-[18px] text-white text-center">
                        $ {{ item.weightedWagered.toFixed(2) }}
                      </td>
                      <td
                        class="lg:py-3 pr-3 whitespace-nowrap text-[18px] text-white text-right rounded-r-lg">
                        <span class="text-[#FFCE00]">$</span> {{ activeTab == 'current' ? getRewardValue(index + 3) : getPrevRewardValue(index + 3) }}
                      </td>
                    </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div v-if="loading == false"  class="block md:hidden">
            <div class="flex justify-center mt-8 mb:mb-24 text-xl" style="font-family: Oxanium, sans-serif;">
              <table class="w-full">
                <thead v-motion-slide-visible-bottom>
                  <tr>
                    <th
                      class="pr-3 text-white text-[15px] md:text-2xl font-semibold text-left">
                      Ranks
                    </th>
                    <th
                      class="px-3 text-white text-[15px] md:text-2xl font-semibold text-center">
                      User
                    </th>
                    <th
                      class="px-3 text-white text-[15px] md:text-2xl font-semibold text-center">
                      Amount
                    </th>
                    <th
                      class="pl-3 text-white text-[15px] md:text-2xl font-semibold text-right">
                      Prize
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-if="top13.length == 0">
                    <td colspan="4" class="text-center text-white font-bold py-4">No data to show</td>
                  </tr>
                  <tr v-motion-slide-visible-bottom v-for="(item, index) in top13" :key="index">
                    <td
                      class="px-1 py-4 whitespace-nowrap text-[15px] text-white font-semibold text-left">
                      {{ getOrdinalSuffix(index + 1) }}
                    </td>
                    <td
                      class="px-1 whitespace-nowrap text-[14px] text-white font-semibold text-center">
                      {{ item.username }}
                    </td>
                    <td
                      class="px-1 whitespace-nowrap text-[14px] text-white font-semibold text-center">
                      $ {{ item.weightedWagered.toFixed(2) }}
                    </td>
                    <td
                      class="px-1 whitespace-nowrap text-[14px] text-white font-semibold text-right">
                      <span class="text-[#FFCE00]">$</span> {{ activeTab == 'current' ? getRewardValue(index) : getPrevRewardValue(index) }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          </div>
        </div>
    </section>
  </template>
  
  <script>
  import axios from 'axios';
  import moment from 'moment-timezone';
  
  export default {
    data() {
      return {
        top3: [],
        top10: [],
        top13: [],

        days: '',
        hours: '',
        minutes: '',
        seconds: '',

        activeTab: 'current',
  
        showTooltip1: false,
        showTooltip2: false,
        
        currentLeaderboard: [],
        previousLeaderboard: [],
  
        startDate: null,
        endDate: null,
  
        prevStartDate: null,
        prevEndDate: null,
  
        notFormattedEndDate: null,
  
        loading: false
      };
    },
    methods: {
      async init() {
        const baseUrl = process.env.VUE_APP_BASE_API;
        this.loading = true
        
        await axios.get(`${baseUrl}/api/leaderboard/roobet`, {headers: {'x-api-key': process.env.VUE_APP_X_API_KEY,},}).then(res => {
          this.currentLeaderboard = res.data.leaderboardData;
  
          this.startDate = moment(res.data.startDate).utc().format('MMMM DD, YYYY')
          this.endDate = moment(res.data.endDate).utc().format('MMMM DD, YYYY')
          
          this.notFormattedEndDate = res.data.endDate
        });

        await axios.get(`${baseUrl}/api/leaderboard/roobet?previous=true`, {headers: {'x-api-key': process.env.VUE_APP_X_API_KEY,},}).then(rest => {
            this.previousLeaderboard = rest.data.leaderboardData;

            this.prevStartDate = moment(rest.data.startDate).utc().format('MMMM DD, YYYY')
            this.prevEndDate = moment(rest.data.endDate).utc().format('MMMM DD, YYYY')
        });

        this.changeLeaderboard()
        this.startTimer();

        this.loading = false
      },

      changeLeaderboard(){
       if(this.activeTab == 'current'){
            const leaderboard = this.currentLeaderboard
              this.top3 = leaderboard.slice(0, 3).map((item) => {
                return item;
              });
    
              this.top10 = leaderboard.slice(3, 13).map((item) => {
                return item;
              });
    
              this.top13 = leaderboard.slice(0, 13).map((item) => {
                return item;
              });
        }
        else{
            const leaderboard = this.previousLeaderboard
            this.top3 = leaderboard.slice(0, 3).map((item) => {
              return item;
            });
  
            this.top10 = leaderboard.slice(3, 13).map((item) => {
              return item;
            });
  
            this.top13 = leaderboard.slice(0, 13).map((item) => {
              return item;
            });
        }
      },
      getOrdinalSuffix(n) {
        const s = ['th', 'st', 'nd', 'rd'],
          v = n % 100;
        return n + (s[(v - 20) % 10] || s[v] || s[0]);
      },
      getRewardValue(rank) {
        const prizes = [750,500,250,150,100,50,50,50,50,25,10,10,5];
  
        return prizes[rank];
      },
      getPrevRewardValue(rank){
        const prizes = [750,500,250,150,100,50,50,50,50,25,10,10,5];
  
        return prizes[rank];
      },
      maskString(str) {
        if (!str || str.length < 2) {
          return str;
        }
        if (!str || str.length <= 4) {
          const start = str.slice(0, 1);
          const end = str.slice(-1);
          const middleAsterisks = '*'.repeat(str.length - 2);
  
          return `${start}${middleAsterisks}${end}`;
        }
        else{
          const start = str.slice(0, 2);
          const end = str.slice(-2);
          const middleAsterisks = '*'.repeat(str.length - 4);
  
          return `${start}${middleAsterisks}${end}`;
        }
      },
      startTimer() {
        const getNextTargetDate = () => {
          const now = moment.utc();
  
          let targetDate = moment.utc(this.notFormattedEndDate).subtract(1, 'seconds');
  
          if (now.isAfter(targetDate)) {
            targetDate.add(1, 'month');
          }
  
          return targetDate;
        };
  
        let targetDate = getNextTargetDate();
        let timerInterval;
  
        const updateTimer = () => {
          const nowUtc = moment.utc();
          const distance = targetDate.diff(nowUtc);
  
          if (distance < 0) {
            this.timer = 'Leaderboard ended';
            this.init();
            clearInterval(timerInterval);
  
            targetDate = getNextTargetDate();
            this.startTimer();
          } else {
            const duration = moment.duration(distance);
            this.days = Math.floor(duration.asDays());
            this.hours = duration.hours();
            this.minutes = duration.minutes();
            this.seconds = duration.seconds();
          }
        };
  
        updateTimer();
        timerInterval = setInterval(updateTimer, 1000);
      }
    },
    mounted() {
      this.init();
    },
  };
  </script>
  
  <style scoped>
  </style>
  
