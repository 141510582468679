<template>
	<!-- Navbar start -->
	<navbar-element v-if="loading == false" v-on:logout="handleLogout()"></navbar-element>
	<!-- Navbar end -->

	<loading-element v-if="loading == true"></loading-element>
	<router-view v-if="loading == false"></router-view>

	<!-- Footer start -->
	<footer-element v-if="loading == false"></footer-element>
	<!-- Footer end -->
</template>

<script>
import axios from 'axios';
import NavbarElement from '../components/NavbarElement.vue';
import FooterElement from '../components/FooterElement.vue';
import LoadingElement from '../components/LoadingElement.vue';
export default {
	components: {
		FooterElement,
		NavbarElement,
		LoadingElement
	},
	data(){
		return {
			loading: false
		}
	},
	methods: {
		handleLogout(){
			this.loading = true

			this.$store.commit('UPDATE_USER', null)
			localStorage.removeItem("vuex");
			localStorage.removeItem("ballyboy-access-token");
			this.isLogout = false
			this.$router.replace("/")

			setTimeout(() => {
				this.loading = false;
			}, 2000);
		},
	},
	mounted() {
		const accessToken = this.$route.query.accessToken;
		if(accessToken != null || accessToken != undefined){
			this.loading = true
			axios.get(process.env.VUE_APP_BASE_API + '/api/user', { 
				headers: {
					'Authorization': `Bearer ${accessToken}`,
				}
			} 
			).then((res)=>{
				if(res){
					localStorage.setItem("ballyboy-access-token", accessToken)
					this.$store.commit('UPDATE_USER', res.data.user)
					
					setTimeout(() => {
						this.loading = false;
						this.$router.replace("/profile")
					}, 2000);
				}
			})
		}
		
	}
}
</script>

<style scoped>

</style>
